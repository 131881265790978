{
  "pooldesc": "최고의 성능을 위해 고도로 최적화된 풀",
  "pooldescVIP": "최고의 성능을 위한 하이퍼 최적화 풀",
  "Network Speed": "네트워크 속도",
  "Pool Speed": "풀 속도",
  "Pool rewards": "풀 보상",
  "Miners count": "채굴자 수",
  "Solutions count": "솔루션 수",
  "Workers count": "근로자 수",
  "Search Miner": "검색 광부",
  "Enter Miner Address": "채굴자 주소 입력",
  "Search": "검색",
  "Real-time speed": "실시간 속도",
  "Today mined": "오늘 채굴",
  "Worker Name": "작업자 이름",
  "Speed": "속도",
  "Submitted Solutions": "제출된 솔루션",
  "Last submit time": "마지막 제출 시간",
  "Online Status": "온라인 상태",
  "Settlement day": "결산일",
  "Amount": "분량",
  "Home": "집",
  "All": "모두",
  "Online": "온라인",
  "Offline": "오프 라인",
  "Invalid": "올바르지 않음",
  "Miners": "광부",
  "Income": "소득",
  "Total Income": "총 수입",
  "Account Balance": "계좌 잔액",
  "Today’s Revenue(Estimated)": "오늘의 수익(예상)",
  "No records": "기록 없음",
  "Miner": "광부",
  "runCmdJoin": "터미널에서 다음 명령을 실행하여 풀에 참가합니다",
  "Pool": "풀",
  "homepageBottomDesc": "Cysic Network는 ZK 혁명을 하이퍼스케일하는 ZK 방지 계층입니다. 주요 목적은 Cysic의 선도적인 CUDA 및 ASIC 개발과 커뮤니티의 컴퓨팅 리소스를 사용하여 모든 ZK 프로젝트에 대한 ZK 증거 생성 및 정산을 촉진하는 것입니다.",
  "Workers": "노동자",
  "Total Workers": "총 근로자 수",
  "Current Epoch": "현재 시대",
  "Block Height": "블록 높이",
  "Workers Count": "작업자 수",
  "somethingWrong": "문제가 있습니다. 나중에 다시 시도하십시오.",
  "Miner does not exist": "광부가 존재하지 않습니다",
  "Address not found": "주소를 찾을 수 없음",
  "Total solutions": "토털 솔루션",
  "Join": "잇다",
  "Rows per page": "페이지당 행 수",
  "Detailed tutorial": "자세한 튜토리얼",
  "Total Payouts": "총 지불금",
  "Search History": "검색 기록",
  "How to join": "참가 방법",
  "No more data": "더 이상 데이터가 없습니다.",
  "Sort by": "정렬 기준",
  "Pool Fee": "수영장 이용료",
  "Search by worker name": "작업자 이름으로 검색",
  "Reduce Pool Fee": "풀 요금 절감",
  "Local Speed": "로컬 속도",
  "Speed(Estimated)": "속도(예상)",
  "Language": "언어",
  "More": "더",
  "Latest 24 Hrs Speed": "최신 24시간 속도",
  "Paid": "지불",
  "Unpaid": "무급의",
  "Average": "평균의",
  "Overview": "개요",
  "Latest Pool Speed": "최신 풀 속도",
  "Pool Rewards": "풀 보상",
  "Pool Solutions": "풀 솔루션",
  "Latest 24 Hrs Solutions": "최신 24시간 솔루션",
  "Shares": "공유",
  "Latest 14 Days Income": "최근 14일 수입",
  "Settlement": "마을",
  "Payments": "지불",
  "Payment Day": "지불일",
  "Transaction Hash": "트랜잭션 해시",
  "Error": "오류",
  "Pending": "보류 중인",
  "Success": "성공",
  "Status": "상태",
  "Rewards": "보상",
  "Solutions Counts": "솔루션 수",
  "Loading": "로드",
  "Total Solutions": "토털 솔루션",
  "MayaDesc": "글로벌 디지털 자산 서비스 플랫폼",
  "Solutions": "솔루션",
  "On-chain Pool Speed": "온체인 풀 속도"
}