import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { formatSpeed, getChartDataWithInput } from 'utils';
import { ChartBaseItem } from 'gql/pool/__generated__/request';
import moment from 'moment';
import Loading from 'components/Loading';
import { RowCenter } from 'components/Row';
import { isMaya, UIVersion } from 'const';

const Wrapper = styled.div`
  width: 100%;
  min-width: 600px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: unset;
    background: rgba(0, 0, 0, 0.3);
    `};
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const SwtichItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #0e0905;
  opacity: 0.5;
  cursor: pointer;

  &.onSelected {
    opacity: 1;
    font-weight: bold;
  }
`;

interface DataConfig {
  data: ChartBaseItem[];
  name: string;
  axisLabelFormater?(value: number): string;
}

export interface ChartTMP1Props {
  xformater?(value: number): string;
  chartName: {
    y1: string;
    y2?: string;
  };
  d1: DataConfig;
  d2?: DataConfig;
  isLoading: boolean;
  showLoading?: boolean;
  tooltipsNameFormater?(value: number): string;
}

function TooltipsNameFormater(value: string | number) {
  return moment(Number(value)).format('MM-DD HH:mm');
}

function Xformater(value: string | number) {
  return `${moment(Number(value)).format('MM-DD')}\n${moment(
    Number(value),
  ).format('HH:mm')}`;
}

const colors = {
  maya: {
    line1: '#2B80FF',
    line2: '#7C3AED',
    line1Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(43, 128, 255, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(43, 128, 255, 0)',
      },
    ]),
    line2Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(124, 58, 237, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(124, 58, 237, 0)',
      },
    ]),
    tooltips: {
      backgroundColor: '#21263E',
      textColor: '#fff',
    },
  },
  community: {
    line1: '#22EEE2',
    line2: '#7C3AED',
    line1Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(34, 238, 226, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(34, 238, 226, 0)',
      },
    ]),
    line2Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(124, 58, 237, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(124, 58, 237, 0)',
      },
    ]),
    tooltips: {
      backgroundColor: '#25222B',
      textColor: '#fff',
    },
  },
  other: {
    line1: '#22EEE2',
    line2: '#7C3AED',
    line1Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(34, 238, 226, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(34, 238, 226, 0)',
      },
    ]),
    line2Linear: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(124, 58, 237, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(124, 58, 237, 0)',
      },
    ]),
    tooltips: {
      backgroundColor: '#fff',
      textColor: '#000',
    },
  },
};

const colorConfig = colors[UIVersion as keyof typeof colors] || colors.other;
export default function ChartTMP1({
  d1,
  d2,
  isLoading,
  xformater,
  tooltipsNameFormater = TooltipsNameFormater,
  showLoading = true,
  chartName,
}: ChartTMP1Props) {
  const { t, i18n } = useTranslation();

  const chartData = useMemo(() => {
    return getChartDataWithInput(d1.data || [], d2?.data || []);
  }, [d1, d2]);

  const options = useMemo(() => {
    const legendName = [d1.name];
    const series = [
      {
        // spee
        name: d1.name,
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: chartData.y1,

        lineStyle: {
          color: colorConfig.line1,
        },
        itemStyle: {
          color: colorConfig.line1,
        },
        areaStyle: {
          opacity: 0.8,
          color: colorConfig.line1Linear,
        },
        markLine: {
          symbol: ['none', 'none'],
          data: [
            {
              type: 'average', // 设置线类型为平均线
              lineStyle: {
                normal: {
                  color: colorConfig.line1, // 设置线的颜色为绿色
                  width: 1, // 设置线的宽度为2px
                  type: 'dashed', // 设置线的类型为实线
                },
              },
              label: {
                formatter: '',
              },
            },
          ],
        },
      },
    ];
    if (d2) {
      legendName.push(d2.name);
      series.push({
        name: d2.name,
        // localSpeed
        type: 'line',
        showSymbol: false,
        smooth: true,
        data: chartData.y2,

        lineStyle: {
          color: colorConfig.line2,
        },
        itemStyle: {
          color: colorConfig.line2,
        },
        areaStyle: {
          opacity: 0.8,
          color: colorConfig.line2Linear,
        },
        markLine: {
          symbol: ['none', 'none'],
          data: [
            {
              type: 'average', // 设置线类型为平均线
              lineStyle: {
                normal: {
                  color: colorConfig.line2, // 设置线的颜色为绿色
                  width: 1, // 设置线的宽度为2px
                  type: 'dashed', // 设置线的类型为实线
                },
              },
              label: {
                formatter: '',
              },
            },
          ],
        },
      });
      if (chartName.y2) {
        (series[0] as any).yAxisIndex = 0;
        (series[1] as any) = {
          ...series[1],
          yAxisIndex: 1,
        };
      }
    }

    let yAxis: any;
    if (chartName.y2) {
      yAxis = [
        {
          name: chartName.y1,
          nameRotate: -0.1,
          nameTextStyle:
            i18n.language === 'en'
              ? {
                  padding: [0, 0, 0, 10],
                  align: 'center',
                }
              : {
                  align: 'center',
                },
          splitLine: {
            lineStyle: {
              color: 'rgba(217, 217, 217, 0.2)',
            },
          },
          axisLabel: {
            formatter: d1?.axisLabelFormater || formatSpeed,
          },
        },
        {
          name: chartName.y2,
          nameTextStyle: {
            padding: [0, 20, 0, 0],
            align: 'center',
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(217, 217, 217, 0.2)',
            },
          },
          axisLabel: {
            formatter: d2?.axisLabelFormater || formatSpeed,
          },
        },
      ];
    } else {
      yAxis = {
        name: chartName.y1,
        nameTextStyle:
          i18n.language === 'en'
            ? {
                padding: [0, 0, 0, 10],
                align: 'center',
              }
            : { align: 'center' },
        splitLine: {
          lineStyle: {
            color: 'rgba(217, 217, 217, 0.2)',
          },
        },
        axisLabel: {
          formatter: d1?.axisLabelFormater || formatSpeed,
        },
      };
    }

    const tooltips = {
      trigger: 'axis',
      backgroundColor: colorConfig.tooltips.backgroundColor,
      borderWidth: '0',
      textStyle: {
        color: colorConfig.tooltips.textColor,
      },

      formatter: function (params: any) {
        let tip = '';

        if (params != null && params.length > 0) {
          tip += tooltipsNameFormater(params[0].name) + '<br />';
          for (let i = 0; i < params.length; i++) {
            if (i !== 0 && !isMaya) {
              tip += '<br />';
            }
            let value = params[i].value;
            tip += `${params[i].marker} ${params[i].seriesName}: ${formatSpeed(
              value,
            )} <br />${params[i].marker} ${t('Average')}: ${
              params[i].seriesName === d1.name
                ? formatSpeed(chartData.y1Average)
                : formatSpeed(chartData.y2Average)
            }<br />`;
          }
        }
        return tip;
      },
    };
    return {
      tooltip: isMobile ? undefined : tooltips,
      grid: chartName?.y2
        ? isMobile
          ? {
              x: 70,
              y: 60,
              x2: 50,
              y2: 40,
            }
          : {
              x: 60,
              y: 30,
              x2: 80,
              y2: 40,
            }
        : isMobile
        ? {
            x: 50,
            y: 30,
            x2: 20,
            d2: 40,
          }
        : {
            x: 60,
            y: 30,
            x2: 20,
            d2: 40,
          },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: chartData.x,

        axisLabel: {
          formatter: xformater || Xformater,
        },
      },
      legend: d2
        ? {
            data: legendName,
            textStyle: {
              color: '#fff',
            },
            right: chartName?.y2 ? (isMobile ? 0 : 180) : 0,
            icon: 'circle',
            itemWidth: 10,
            itemHeight: 10,
          }
        : undefined,
      yAxis,
      series,
    };
  }, [chartData, d1, d2, i18n.language, chartName]);

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      {showLoading && isLoading ? (
        <RowCenter>
          <Loading />
        </RowCenter>
      ) : (
        <ReactECharts
          option={options}
          notMerge={true}
          lazyUpdate={true}
          style={{ width: '100%', height: 200 }}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
        />
      )}
    </Wrapper>
  );
}
