import { poolRequestApi } from 'gql';
import { UserOverviewCharts } from 'gql/pool/__generated__/request';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import styled from 'styled-components';
import ChartTMP1, { ChartTMP1Props } from 'components/Chart/tmp1';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Loading from 'components/Loading';
import { AutoColumn } from 'components/Column';
import { TYPE } from 'theme';
import {
  OverviewItem,
  Overview as OverviewComponent,
} from 'components/Overview';
import { RowCenter } from 'components/Row';
import Value from 'components/Value';

const ChartWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap:40px;
  `};
`;

const LoadingWrapper = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 100px;
  `};
`;

function Overview() {
  const { t } = useTranslation();
  const [overviewChart, setOverviewChart] = useState<UserOverviewCharts>();
  const [isLoading, setIsLoading] = useState(false);

  async function getOverViewChart() {
    if (!user.id) return;
    try {
      setIsLoading(true);
      const res = await poolRequestApi.userOverviewCharts({
        userId: user.id,
      });
      setOverviewChart(res.userOverviewCharts);
      setIsLoading(false);
    } catch (e) {
      console.error('getOverViewChart error');
      console.error(e);
    }
  }

  useEffect(() => {
    if (user.id) {
      getOverViewChart();
    } else {
      setOverviewChart(undefined);
    }
  }, [user.id]);

  const data = useMemo(() => {
    const shares: ChartTMP1Props = {
      isLoading: false,

      chartName: {
        y1: t('Latest 24 Hrs Solutions'),
      },
      d1: {
        data: overviewChart?.latestShares || [],
        name: t('Solutions'),
      },
    };

    const speed = {
      isLoading: false,

      chartName: {
        y1: t('Latest 24 Hrs Speed'),
      },
      d1: {
        data: overviewChart?.latestSpeeds || [],
        name: t('Speed(Estimated)'),
      },
      d2: {
        data: overviewChart?.latestLocalSpeeds || [],
        name: t('Local Speed'),
      },
    };

    const income: ChartTMP1Props = {
      isLoading: false,

      chartName: {
        y1: t('Latest 14 Days Income'),
      },
      d1: {
        data: overviewChart?.latestIncomes || [],
        name: t('Income'),
      },
      xformater: (value: number) => {
        return `${moment(Number(value)).format('MM-DD')}`;
      },
      tooltipsNameFormater: (value: string | number) => {
        return `${moment(Number(value)).format('MM-DD')}`;
      },
    };

    return {
      shares,
      speed,
      income,
    };
  }, [overviewChart, t]);

  return (
    <>
      <OverviewComponent>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.totalIncomes} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.main fontSize={14}>Aleo</TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Total Income')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value value={user.userInfo.totalShares} decimals={2} />
              ) : (
                '-'
              )}
            </TYPE.gMain>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Total Solutions')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userWorkerStat ? (
                <Value value={user.userWorkerStat?.online} />
              ) : (
                '-'
              )}
            </TYPE.gMain>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Online')}</TYPE.desc>
        </OverviewItem>
        <OverviewItem>
          <RowCenter
            style={{
              gap: '4px',
              alignItems: 'baseline',
            }}
          >
            <TYPE.gMain fontSize={28} mobileFz={16}>
              {user.userInfo ? (
                <Value
                  value={user.userInfo.realtimeHashRate}
                  speed
                  decimals={2}
                />
              ) : (
                '-'
              )}{' '}
            </TYPE.gMain>
            <TYPE.main fontSize={14}>
              {user.userInfo?.realtimeHashRateUnit}
            </TYPE.main>
          </RowCenter>
          <TYPE.desc textAlign={'center'}>{t('Speed')}</TYPE.desc>
        </OverviewItem>
      </OverviewComponent>
      <ChartWrapper>
        {isLoading ? (
          <LoadingWrapper>
            <Loading size={30} />
            <TYPE.main>{t('Loading')}...</TYPE.main>
          </LoadingWrapper>
        ) : (
          <>
            <ChartTMP1 {...data.shares} />
            <ChartTMP1 {...data.speed} />
            <ChartTMP1 {...data.income} />
          </>
        )}
      </ChartWrapper>
    </>
  );
}

export default observer(Overview);
