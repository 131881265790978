import { AutoColumn } from 'components/Column';
import Row, { RowCenter } from 'components/Row';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import application from 'stores/application';
import Value from 'components/Value';
import SearchMiner from 'components/SearchMiner';
import Command from 'components/Command';
import PageTitle from 'components/PageTItle';
import { hideText } from 'polished';
import JoinContent from 'components/JoinContent';
import { isCommunity, isMaya } from '../../const';
import SpeedChart from './SpeedChart';
import PoolRewardChart from './PoolRewardChart';
import ChartTMP1, { ChartTMP1Props } from 'components/Chart/tmp1';
import moment from 'moment';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 100px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 50px;
  `};
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  z-index: 1;

  &.joindesc {
    gap: 40px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    `};
  }
`;

const LeftBorder = styled.div`
  width: 181px;
  height: 2px;
  border-radius: 0px 0px 0px 0px;
  border: 2px solid;
  opacity: 0.56;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(33, 233, 250, 1)
    )
    2 2;
`;

const RightBorder = styled.div`
  width: 181px;
  height: 2px;
  border-radius: 0px 0px 0px 0px;
  border: 2px solid;
  opacity: 0.56;
  border-image: linear-gradient(
      90deg,
      rgba(33, 233, 250, 1),
      rgba(255, 255, 255, 0)
    )
    2 2;
`;

const Banner2Img = styled.img`
  width: 100%;
  margin-top: -300px;
  z-index: -1;
`;
const ProductBox = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  padding: 40px 0;
  gap: 30px;
  flex-wrap: wrap;
`;

const LeftProds = styled.div`
  display: flex;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: fit-content;
  width: 620px;
`;

const RightProds = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const ProdItem = styled.div`
  width: 300px;
  height: 300px;
  padding: 30px;
  background: #10141a;
  border-radius: 28px 28px 28px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.gradient {
    align-items: flex-start;
    background: linear-gradient(
      137deg,
      rgba(24, 40, 44, 1),
      rgba(24, 36, 46, 1)
    );
  }

  &.gradient2 {
    align-items: flex-start;
    background: linear-gradient(
      137deg,
      rgba(46, 29, 68, 1),
      rgba(16, 20, 26, 1)
    );
  }
`;

const ProdNetwork = styled.div`
  width: 630px;
  height: 300px;
  background: linear-gradient(137deg, rgba(46, 29, 68, 1), rgba(16, 20, 26, 1));
  border-radius: 28px 28px 28px 28px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
  img {
    position: absolute;
    width: 300px;
    left: 0;
    bottom: 0;
  }
`;

const PartnersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 80px;
  z-index: 1;
  margin-top: 100px;
  img {
    width: 120px;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 70px;
`;

const BlogBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`;

const Blog = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  height: 200px;
  border-radius: 14px;
  background: #10141a;
  width: 45%;
  gap: 10px;
`;

const Overview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-top: 40px;
  width: 100%;

  .mayaBorder {
    display: none;
  }

  &.maya {
    /* width: 80%; */
    flex-wrap: wrap;
    margin-top: -200px;
    z-index: 1;
    justify-content: flex-start;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      >div {
      width:50% !important;
      gap: 0;
      }
    `};
    .mayaBorder {
      display: block;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      display: none;
    `};
      &.row {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.02) 2%,
          rgba(255, 255, 255, 0.3) 30%,
          rgba(255, 255, 255, 0.3) 60%,
          rgba(255, 255, 255, 0.02) 98%
        );
      }

      &.column1 {
        position: absolute;
        top: 0;
        left: 35%;
        width: 2px;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.02) 2%,
          rgba(255, 255, 255, 0.3) 30%,
          rgba(255, 255, 255, 0.3) 60%,
          rgba(255, 255, 255, 0.02) 98%
        );
      }
      &.column2 {
        position: absolute;
        top: 0;
        right: 35%;
        width: 2px;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.02) 2%,
          rgba(255, 255, 255, 0.3) 30%,
          rgba(255, 255, 255, 0.3) 60%,
          rgba(255, 255, 255, 0.02) 98%
        );
      }
    }

    > div {
      width: 30%;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -100px;
    `};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  flex-wrap: wrap;
  gap: 0px;
  `};
`;

const OverviewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;

  div {
    text-align: center;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 50%;
  flex-shrink: 0;
  padding: 10px;
  gap: 10px;
  `};
`;

const BottomBlock = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  z-index: -1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 20px;
  padding: 10px 20px;
  `};

  em {
    color: #1dc5da;
  }

  div {
    line-height: 1.6;
  }

  .leftBorder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    border: 1px solid;
    border-image: linear-gradient(
        234deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.48),
        rgba(255, 255, 255, 0)
      )
      1 1;
  }
  .bottomBorder {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 50%;
    height: 2px;
    border: 1px solid;
    border-image: linear-gradient(
        234deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.48),
        rgba(255, 255, 255, 0)
      )
      1 1;
  }
`;

const Code = styled.div`
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.c.t01};
  background-color: #111935;
  border-radius: 12px;
`;

const SearchWrapepr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  position: relative;
  z-index: 3;
  margin-top: 100px;
  &.maya {
    margin-top: 20px;
  }
  .inputRow {
    opacity: 0.8;
  }

  input {
    padding-right: 100px !important;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 95%;
  margin-top: 50px;
  `};
`;

const StyledSearchButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 42px !important;
  top: 45%;
  `};
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 995px;
  height: fit-content;
  z-index: 0;
  margin-top: -100px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  margin-top: -70px;
  `};
  video {
    width: 100%;
    &::-webkit-media-controls-overlay-play-button {
      display: none;
    }
  }
  img {
    width: 140%;
  }
`;

const BannerImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 995px;
  height: fit-content;
  z-index: -1;
  margin-top: -180px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  margin-top: -70px;
  `};
`;
const VideoFrontImg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    53.12% 53.12% at 51.46% 46.88%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 47.5%,
    #000000 100%
  );
  background-position: center;
  z-index: 1;
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap:40px;
  `};
`;

function HomePool() {
  const [searchValue, setSerchValue] = useState('');
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [searchActive, setSearchActive] = useState(false);

  function search() {
    if (searchValue) {
      history.push(`/machine/${searchValue}`);
    }
  }

  useEffect(() => {
    const container: Element | null = document.querySelector('.container');
    const video = document.getElementById('bannerVideo');
    function playVideo() {
      if (container && video) {
        const { scrollTop, clientHeight, scrollHeight } = container;

        const time =
          (video as any).duration * (scrollTop / (scrollHeight - clientHeight));
        (video as any).currentTime = time;
      }
      window.requestAnimationFrame(playVideo);
    }
    if (container && video) {
      container.addEventListener('scroll', playVideo);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', playVideo);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      application.getOverview();
    }, 30000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
  const speedProps: ChartTMP1Props = useMemo(() => {
    return {
      isLoading: false,

      chartName: {
        y1: t('Network Speed'),
        y2: t('On-chain Pool Speed'),
      },
      d1: {
        data: application.overview?.latestNetworkSpeeds || [],
        name: t('Network Speed'),
      },
      d2: {
        data: application.overview?.latestPoolSpeeds || [],
        name: t('On-chain Pool Speed'),
      },
    };
  }, [application.overview, i18n.language]);

  const poolRewardProps: ChartTMP1Props = useMemo(() => {
    return {
      isLoading: false,
      xformater: (value: number) => {
        return `${moment(Number(value)).format('MM-DD')}`;
      },
      tooltipsNameFormater: (value: string | number) => {
        return `${moment(Number(value)).format('MM-DD')}`;
      },
      chartName: {
        y1: t('Pool Rewards'),
        y2: t('Solutions Counts'),
      },
      d1: {
        data: application.overview?.latestPoolRewards || [],
        name: t('Rewards'),
      },
      d2: {
        data: application.overview?.latestPoolSolutions || [],
        name: t('Solutions Counts'),
      },
    };
  }, [application.overview, i18n.language]);
  return (
    <Wrapper>
      <Block>
        <PageTitle hideText={searchActive} />
        <SearchWrapepr className={classNames(isMaya && 'maya')}>
          <SearchMiner handleActive={setSearchActive} />
        </SearchWrapepr>
        {/* <BannerImg src={require('assets/img/banner.png')} /> */}
        {isCommunity && (
          <VideoWrapper>
            {isMobile ? (
              <img src={require('assets/img/community-banner.png')} alt="" />
            ) : (
              <>
                <video id="bannerVideo">
                  <source
                    src={require('url:../../assets/video/banner.mp4')}
                    type="video/mp4"
                  ></source>
                </video>
              </>
            )}
            <VideoFrontImg />
          </VideoWrapper>
        )}
        {isMaya && <BannerImg src={require('assets/img/zk-home-banner.png')} />}
        <Overview className={isMaya ? 'maya' : ''}>
          <div className="mayaBorder row" />
          <div className="mayaBorder column1" />
          <div className="mayaBorder column2" />
          <OverviewItem className="overviewItem">
            <TYPE.gMain
              fontSize={28}
              mobileFz={22}
              style={{ display: 'flex', alignItems: 'baseline' }}
            >
              {application.overview ? (
                <>
                  <Value
                    value={application.overview?.epochInfo?.currentEpoch}
                  />
                  &nbsp;
                  <TYPE.gMain fontSize={20} mobileFz={14}>
                    {`(${application.overview?.epochInfo?.currentBlockInEpoch}/${application.overview?.epochInfo?.totalBlocksInEpoch})`}
                  </TYPE.gMain>
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>

            <TYPE.desc>{t('Current Epoch')}</TYPE.desc>
          </OverviewItem>
          <OverviewItem className="overviewItem">
            <TYPE.gMain fontSize={28} mobileFz={22}>
              {application.overview ? (
                <>
                  <Value
                    value={application.overview?.blockHeight}
                    formatFirst={false}
                  />
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>

            <TYPE.desc>{t('Block Height')}</TYPE.desc>
          </OverviewItem>
          <OverviewItem className="overviewItem">
            <TYPE.gMain
              fontSize={28}
              mobileFz={22}
              style={{ display: 'flex', alignItems: 'baseline' }}
            >
              {application.overview ? (
                <>
                  <Value value={application.overview?.networkHashrate} speed />
                  <TYPE.gMain fontSize={20} mobileFz={14}>
                    &nbsp;{application.overview?.networkHashrateUnit}
                  </TYPE.gMain>
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>

            <TYPE.desc>{t('Network Speed')}</TYPE.desc>
          </OverviewItem>
          <OverviewItem className="overviewItem">
            <TYPE.gMain
              fontSize={28}
              mobileFz={22}
              style={{ display: 'flex', alignItems: 'baseline' }}
            >
              {application.overview ? (
                <>
                  <Value value={application.overview?.poolHashrate} speed />
                  <TYPE.gMain fontSize={20} mobileFz={14}>
                    &nbsp;{application.overview?.poolHashRateUnit}
                  </TYPE.gMain>
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.desc>{t('Pool Speed')}</TYPE.desc>
          </OverviewItem>
          {/* <OverviewItem className='overviewItem'>
            <TYPE.gMain fontSize={28} mobileFz={22}>
              {application.overview ? (
                <>
                  <Value value={application.overview?.poolRewards} />
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.desc>{t('Pool rewards')}</TYPE.desc>
          </OverviewItem>
          <OverviewItem className='overviewItem'>
            <TYPE.gMain fontSize={28} mobileFz={22}>
              {application.overview ? (
                <>
                  <Value value={application.overview?.minerCount} />
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.desc>{t('Miners count')}</TYPE.desc>
          </OverviewItem>
          <OverviewItem className='overviewItem'>
            <TYPE.gMain fontSize={28} mobileFz={22} uppercase>
              {application.overview ? (
                <>
                  <Value value={application.overview?.solutionCount} />
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.desc>{t('Solutions count')}</TYPE.desc>
          </OverviewItem> */}
          <OverviewItem className="overviewItem">
            <TYPE.gMain fontSize={28} mobileFz={22} uppercase>
              {application.overview ? (
                <>
                  <Value value={application.overview?.workerCount} />
                </>
              ) : (
                '-'
              )}
            </TYPE.gMain>
            <TYPE.desc>{t('Workers Count')}</TYPE.desc>
          </OverviewItem>
          {(isCommunity || isMaya) && (
            <OverviewItem className="overviewItem">
              <TYPE.gMain
                fontSize={28}
                mobileFz={22}
                uppercase
                style={{ display: 'flex', alignItems: 'baseline' }}
              >
                {application.overview ? (
                  <>
                    <Value
                      value={application.overview?.poolFee * 100}
                      decimals={2}
                    />{' '}
                    <TYPE.gMain fontSize={20} mobileFz={14}>
                      &nbsp;%
                    </TYPE.gMain>
                  </>
                ) : (
                  '-'
                )}
              </TYPE.gMain>
              <TYPE.desc>{t('Pool Fee')}</TYPE.desc>
            </OverviewItem>
          )}
        </Overview>
      </Block>
      <ChartWrapper>
        <ChartTMP1 {...speedProps} />
        <ChartTMP1 {...poolRewardProps} />
      </ChartWrapper>
      <JoinContent />
    </Wrapper>
  );
}

export default observer(HomePool);
